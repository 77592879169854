import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { putInitialLead } from "../utils/api";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Container } from "../components/Container";
import { Spinner } from "../components/Spinner";

export function InitPage() {
  const { heyflowId, data } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (data === undefined) return;

    (async () => {
      try {
        const replaced = data!.replace(/-/g, "+").replace(/_/g, "/").replace(/\*/g, "=");
        const decoded = JSON.parse(decodeURIComponent(atob(replaced)));
        if (decoded["Heyflow Id"] !== heyflowId) return;

        await putInitialLead(decoded);
      } finally {
        navigate(`/${heyflowId}`, { replace: true });
      }
    })();
  }, [heyflowId, data, navigate]);

  return (
    <div className="min-h-screen flex flex-col">
      <Header></Header>
      <div className="flex-1">
        <Container className="flex-1">
          <>
            <div className="flex justify-center my-10">
              <Spinner size="large"></Spinner>
            </div>
            <span className="text-center block text-base md:text-lg">Dein Angebot wird geladen</span>
          </>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
}
