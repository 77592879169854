import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./index.css";
import { ContextProviders } from "./contexts/ContextProviders";
import { Main } from "./pages/Main";
import { Products } from "./pages/Products";
import { Product } from "./pages/Product";
import { Root } from "./pages/Root";
import { CartEntry } from "./pages/CartEntry";
import { InitPage } from "./pages/Init";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: ":heyflowId?",
        element: <Main />,
      },
      {
        path: ":heyflowId/products/:type/:page?",
        element: <Products />,
      },
      {
        path: ":heyflowId/product/:productId",
        element: <Product />,
      },
      {
        path: ":heyflowId/cart/:cartItemIndex",
        element: <CartEntry />,
      },
      {
        path: ":heyflowId/init/:data",
        element: <InitPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  //<React.StrictMode>
  <ContextProviders>
    <RouterProvider router={router} />
  </ContextProviders>
  //</React.StrictMode>
);
